
import { defineComponent, onMounted, ref } from "vue";

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { Mutual } from "@/core/model/Mutual";
import { Actions } from "@/store/enums/StoreEnums";
import moment from "moment";
import MutualInfo from "@/views/mutual/MutualInfo.vue";
import { setCurrentPageActions } from "@/core/helpers/toolbar";

export default defineComponent({
  name: "mutual-profile",
  components: {
    MutualInfo,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const mutual = ref<Mutual>({} as Mutual);
    const actionModal = "edit";
    const loading = ref(false);

    onMounted(() => {
      setCurrentPageBreadcrumbs("Mutual", ["Mutual", "Información"]);
      setCurrentPageActions(false, false, []);
      loadData();
    });

    const loadData = () => {
      loading.value = true;
      store.dispatch(Actions.MUTUAL_GET, route.params.uuid).then(() => {
        mutual.value = store.state.MutualesModule.mutual;
        loading.value = false;
      });
    };

    const formatDateTime = (value) => {
      return value === undefined
        ? ""
        : moment(value).format("DD/MM/YYYY hh:mm a");
    };

    return {
      mutual,
      formatDateTime,
      actionModal,
      loadData,
      loading,
    };
  },
});
